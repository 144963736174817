import { useCallback, useEffect, useState } from "react"
import { useAuth } from "../../../Providers/AuthProvider"
import { useStorage } from "../../../Providers/StorageProvider"
import { Table } from "react-bootstrap"
import CurrentTime from "../../../components/CurrentTime"
import "./index.scss"
import CricketIcon from "mdi-react/CricketIcon"

export default ({ marketId, betList }) => {
  const { requestsToApi } = useAuth()
  const { userName, userLevel } = useStorage()
  const [resData, setResData] = useState(null)
  const [response, setResponse] = useState(null)
  let triggerTicker = true

  const getBetTickerDataReq = useCallback(async () => {
    try {
      if (triggerTicker) {
        triggerTicker = false
        if (betList) {
          let response = await requestsToApi.get(
            `agency/${userName}/report/order-list?type=matched&page=1&marketId=${marketId}`
          )
          setResponse(response.data.result.pageRows)
        } else {
          let response = await requestsToApi.get(`agency/${userName}/report/bet-ticker?marketId=${marketId}`)
          setResData(response.data.result)
        }
      }
    } catch (error) {
      console.log(error?.message)
    } finally {
      triggerTicker = true
    }
  }, [userName, requestsToApi, betList, marketId])

  useEffect(() => {
    let timer = setInterval(() => {
      if (triggerTicker)
      getBetTickerDataReq()
    }, 1500)
    return () => clearInterval(timer)
  }, [getBetTickerDataReq])

  useEffect(() => {
    getBetTickerDataReq()
  }, [getBetTickerDataReq])

  const addCommas = nStr => {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    if (Math.abs(+nStr)  < 100) {
      let res = 0
      x.length > 1 ?
        res = x1 + x2.substring(0,3) :
        res = x1
      return res
    }
    return x1
  }

  return (
    <div>
      {resData && resData.length && !betList ? (
        <Table striped hover variant="dark">
          <thead>
            <tr>
              {["Member", "Event", "Market", "Selection"].map((title, index) => (
                <th key={index} scope="col" className="text-align-left">
                  {title}
                </th>
              ))}
              {["Odd req.", "Ave. matched", "Matched", "Unmatched", "Profit/Liability"].map((title, index) => (
                <th style={{ textAlign: "right" }} key={index} scope="col">
                  {title}
                </th>
              ))}
              <th scope="col">Last Updated</th>
            </tr>
          </thead>
          <tbody>
            {resData.map(item => (
              <tr key={item.bet_num}>
                <td className="text-align-left">{item.memberName}</td>
                <td className="text-align-left">{item.eventName}</td>
                <td className="text-align-left">{item.marketName}</td>
                <td
                  style={{
                    backgroundColor: item.side === 1 ? "#fac9d4" : "#A6D8FF",
                    color: "black",
                    width: "21%"
                  }}
                  className="text-align-left"
                >
                  {item.selectionName}
                  {
                    item?.marketBettingType === 'LINE' &&
                    <>
                      <span> (Runs:{item.line})</span>
                      <span className="ml-2">
                          {item?.statusLabel === 'Ball Running' &&
                            <CricketIcon />
                          }
                        </span>
                    </>
                  }
                </td>
                <td className="text-align-right">{addCommas(parseFloat(item.oddsRequested).toFixed(2))}</td>
                <td className="text-align-right">{addCommas(parseFloat(item.averageOdds).toFixed(2))}</td>
                <td className="text-align-right">{addCommas(item.sizeMatched)}</td>
                <td className="text-align-right">{addCommas(item.sizeRemaining)}</td>
                <td className={item.profitLiability >= 0 ? "-positive text-align-right" : "-negative text-align-right"}>
                  {addCommas(Math.ceil(item.profitLiability))}
                </td>
                <td>
                  <CurrentTime format="HH:mm:ss">{item.lastUpdatedDate}</CurrentTime>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <></> // !betList && <div className="message">No bets matching this filter, try again</div>
      )}
      {response && response.length && betList ? (
        <Table striped hover variant="dark">
          <thead>
            <tr>
              {["Member", "Placed", "Selection", "Bet ID", "In Play.", "1-Click", "Type", "Odds", "Stake"].map(
                (title, index) => (
                  <th key={index} scope="col" className="text-align-left">
                    {title}
                  </th>
                )
              )}
              {["Potential Profit", "Liability", "Agent PT"].map((title, index) => (
                <th className="text-align-right" key={index} scope="col">
                  {title}
                </th>
              ))}
              {(userLevel === "MA" || userLevel === "SMA") && (
                <th className="text-align-right" scope="col">
                  MA PT
                </th>
              )}
              {userLevel === "SMA" && (
                <th className="text-align-right" scope="col">
                  CUS PT
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {response.map(item => (
              <tr key={item.bet_num}>
                <td className="text-align-left">
                  {item.loginName} ({item.userName})
                </td>
                <td>
                  {/* <CurrentTime format="YYYY/MM/DD HH:mm:ss">{item.lastMatchedDate}</CurrentTime> */}
                  <CurrentTime format="YYYY/MM/DD HH:mm:ss">{item.betPlacedDate}</CurrentTime>
                </td>
                <td className="text-align-left">{item.selectionName}</td>
                <td className="text-align-left">{item.apolloBetId}</td>
                <td>{item.inPlay ? "Y" : "N"}</td>
                <td>{item.oneClick ? "Y" : "N"}</td>
                <td>{item.side ? "Lay" : "Back"}</td>
                <td>{addCommas(parseFloat(item.averagePrice).toFixed(3))}</td>
                <td>{addCommas(parseFloat(item.sizeMatched).toFixed(2))}</td>
                <td className="text-align-right">{addCommas(parseFloat(item.profitLoss).toFixed(2))}</td>
                <td className="text-align-right">{item.liability ? addCommas(parseFloat(item.liability).toFixed(2)) : "-"}</td>
                <td className="text-align-right">{item.agentWin}</td>
                {(userLevel === "MA" || userLevel === "SMA") && <td className="text-align-right">{item.maWin}</td>}
                {userLevel === "SMA" && <td className="text-align-right">{item.smaWin}</td>}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <></>//  betList && <div className="message">No bets for this market</div>
      )}
    </div>
  )
}
