
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Table } from 'react-bootstrap';
import './index.scss';
import RoundResults from '../../../components/RoundResults/RoundResults';
import { useAuth } from '../../../Providers/AuthProvider';
const DashBoard_Url = "https://blastoff-dashboard.fawk.app";

export const UerRoundDetails = ({ roundId, memberCode }) => {
    const [userRoundDetails, setUserRoundDetails] = useState();
    const [userLoginName, setUserLoginName] = useState();
    const [gameId, setGameId] = useState();
    const { requestsToApi } = useAuth();

    const getUerName = async () => {
        const url = `/agency/account/get-loginname?dl=${memberCode}`
        const response = await requestsToApi.get(url);
        if (response.status === 200) {
            const data = response?.data?.result;
            setUserLoginName(data);
        };
    }

    useEffect(() => {
        getUerName();
    }, []);

    const handleFetchUserRoundDetails = () => {
        axios.post(`${DashBoard_Url}/api/dashboard/getUserDetailsAndResult`, {
            roundId: roundId,
            operatorId: "9414",
            username: userLoginName
        }).then((response) => {
            if (response?.data?.success) {
                const data = response?.data?.data;
                setUserRoundDetails(data);
                setGameId(Number(data[0]?.gameId));
            }
        }).catch((e) => {
            console.log("error while fetching round details", e);
        });
    }

    useEffect(() => {
        if (userLoginName && roundId) {
            handleFetchUserRoundDetails();
        }
    }, [userLoginName, roundId]);

    return (
        <div>
            <div>
                <div> <h1 className='uppercase text-boxdark dark:text-white'>User Rounds Details</h1>
                    Round Id- {userRoundDetails?.length > 0 && userRoundDetails[0]?.roundId}
                </div>
            </div>
            <div>
                {gameId != 67722 && (gameId < 70000 || gameId > 70200) ? <NormalTable userRoundDetails={userRoundDetails} />
                    : <OriginalsTable userRoundDetails={userRoundDetails} gameId={gameId} />
                }
            </div>
            {gameId != 67722 && (gameId < 70000 || gameId > 70200) && <  RoundResults roundId={roundId} />}
            {/* After discussion Hardcoded games Id passes here */}
        </div>
    )
}

const NormalTable = ({ userRoundDetails }) => {
    return (
        <Table striped hover variant="dark" className="user-roundDetails-table">
            <thead>
                <tr>
                    <th>
                        Placed
                    </th>
                    <th>
                        Selection
                    </th>
                    <th>
                        Type
                    </th>
                    <th>
                        Request Odds
                    </th>
                    <th>
                        Stake
                    </th>
                    <th>
                        Profit Loss
                    </th>
                    <th>
                        Status
                    </th>
                </tr>
            </thead>
            <tbody>
                {userRoundDetails?.length == 0 ? <tr><td>Loading...</td></tr> :
                    userRoundDetails?.map((item, key) => (
                        <tr key={key}>
                            <td>
                                <p>
                                    {item?.createdAt?.split(".")[0]}
                                </p>
                            </td>
                            <td>
                                <p>
                                    {item?.runnerName}
                                </p>
                            </td>
                            <td >
                                <p>
                                    {item?.isBack == 1 ? "Back" : "Lay"}
                                </p>
                            </td>
                            <td >
                                <p>
                                    {item?.requestedOdds}
                                </p>
                            </td>
                            <td >
                                <p>
                                    {item?.reqStake}
                                </p>
                            </td>
                            <td >
                                <p
                                    style={{
                                        color: item?.downpl > 0 ? "green" : "red"
                                    }}
                                >
                                    {item?.downpl}
                                </p>
                            </td>
                            <td >
                                <p>
                                    {item?.status}
                                </p>
                            </td>
                        </tr >
                    ))
                }
            </tbody >
        </Table >
    )
}

export const OriginalsTable = ({ userRoundDetails }) => {
    const RenderCardTable = (data) => {
        return (
            <table>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}
                        >
                            {Object.entries(item).map(([key, value]) => (
                                <React.Fragment key={key}>
                                    <td>{key}</td>
                                    <td>{value}</td>
                                </React.Fragment>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };
    return (
        <div
        >
            <Table striped hover variant="dark" className="user-roundDetails-table">
                <thead>
                    <tr >
                        <th>
                            Placed
                        </th>
                        <th>
                            Selection
                        </th>
                        <th>
                            Stake
                        </th>
                        <th>
                            Profit Loss
                        </th>
                        <th>
                            Status
                        </th>
                        <th>
                            Winner
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {userRoundDetails?.length == 0 ? <tr><td>Loading...</td></tr> :
                        userRoundDetails?.map((item, key) => (
                            <tr
                                key={key}
                            >
                                <td>
                                    <p>
                                        {item.createdAt?.split(".")[0]}
                                    </p>
                                </td>
                                <td>
                                    {
                                        item.cards?.length > 0 ? RenderCardTable(item?.cards) : item?.requestedOdds
                                    }
                                </td>
                                <td >
                                    <p>
                                        {item?.reqStake}
                                    </p>
                                </td>
                                <td >
                                    <p
                                        style={{
                                            color: item?.downpl > 0 ? "green" : "red"
                                        }}
                                    >
                                        {item?.downpl}
                                    </p>
                                </td>
                                <td >
                                    <p>
                                        {item?.status}
                                    </p>
                                </td>
                                <td >
                                    <p>
                                        {item?.winner ? item?.winner[0] : "-"}
                                    </p>
                                </td>
                            </tr >
                        ))
                    }
                </tbody>
            </Table>
        </div>
    )
}
