import { Table } from "react-bootstrap"
import { numberWithCommas } from "../../../utils/dataTransform"
import StringToRecolorNumber from "../../../components/StringToRecolorNumber"
import CurrentTime from "../../../components/CurrentTime"

export default ({ data, userLevel }) => {
  return (
    <div className="bet-list-report__past">
      <div className="past__body">
        <Table striped hover variant="dark">
          <thead>
            {userLevel !== "Member" ? (
              <tr>
                {["Member", "Settled", "Description", "In Play", "1-Click", "Type", "Placed"].map(title => (
                  <th key={Math.random()} scope="col" className="text-align-left">
                    {title}
                  </th>
                ))}
                {["Odds", "Stake", "Status", "MEMBER Win/Loss", "AGENT Win/Loss"].map(title => (
                  <th key={Math.random()} scope="col">
                    {title}
                  </th>
                ))}
                {(userLevel == "SMA" || userLevel == "MA") && <th scope="col">MA Win/Loss</th>}
                {userLevel === "SMA" && <th scope="col">CUS Win/Loss</th>}
              </tr>
            ) : (
              <tr>
                {["Settled", "Description", "IP Adress", "Type"].map(title => (
                  <th key={Math.random()} scope="col" className="text-align-left">
                    {title}
                  </th>
                ))}
                {["Odds", "Stake", "Profit/Loss", "Status"].map(title => (
                  <th key={Math.random()} scope="col">
                    {title}
                  </th>
                ))}
              </tr>
            )}
          </thead>
          <tbody>
            {userLevel !== "Member" && data && data.pageRows && data.pageRows.length ? (
              data.pageRows.map(item => (
                <tr key={item.apolloBetId}>
                  <td className="text-align-left">{`${item.loginName} (${item.userName})`}</td>
                  <td className="text-align-left">
                    <CurrentTime format="DD/MM/YYYY HH:mm:ss">{item.lastSettledDate}</CurrentTime>
                  </td>
                  <td className="text-align-left">
                    <p>{item.eventName}</p>
                    <p>{`${item.selectionName} - ${item.marketName}`}</p>
                    {item.marketType === "INNINGS_RUNS" &&
                      <p>{`Runs: ${item.line}`}</p>
                    }                    <p>{`Bet ID ${item.apolloBetId}`}</p>
                    <p>
                      Placed Date &nbsp;
                      <CurrentTime format="DD/MM/YYYY HH:mm:ss">{item.betPlacedDate}</CurrentTime>
                    </p>
                  </td>
                  <td className="text-align-left">{item.inPlay ? "Y" : "N"}</td>
                  <td className="text-align-left">{item.oneClick ? "Y" : "N"}</td>
                  <td>
                    {item.marketBettingType === "ODDS" ? (item.side === 0 ? "Back" : "Lay") : (item.side === 0 ? "Yes" : "No")}
                  </td>
                  <td>{item.placedOn}</td>
                  <td>{item.averagePrice}</td>
                  <td>{numberWithCommas(item.sizeTotal)}</td>
                  <td className={item.outcome === "WON" ? "-positive" : "-negative"}>{item.outcome}</td>
                  <td>
                    <StringToRecolorNumber>{item.memberWin}</StringToRecolorNumber>
                  </td>
                  <td>
                    <StringToRecolorNumber>{item.agentWin}</StringToRecolorNumber>
                  </td>
                  {/* {userLevel === "SMA" || */}
                  {(userLevel == "SMA" || userLevel == "MA") && (
                    <td>
                      <StringToRecolorNumber>{item.maWin}</StringToRecolorNumber>
                    </td>
                  )}
                  {userLevel === "SMA" && (
                    <td>
                      <StringToRecolorNumber>{item.smaWin}</StringToRecolorNumber>
                    </td>
                  )}
                </tr>
              ))
            ) : userLevel === "Member" && data ? (
              data.map(item => (
                <tr key={item.apolloBetId}>
                  <td className="text-align-left">
                    {<CurrentTime format="DD/MM/YYYY HH:mm:ss">{item.lastSettledDate}</CurrentTime>}
                  </td>
                  <td className="text-align-left">
                    <p>{item.eventName}</p>
                    <p>{`${item.selectionName} - ${item.marketName}`}</p>
                    {/* <p>{`Bet ID ${item.apolloBetId} l Placed: ${<CurrentTime format="DD/MM/YYYY HH:mm:ss">{item.betPlacedDate}</CurrentTime>}`}</p> */}
                    <p>
                      Bet Id {item.apolloBetId} l Placed: &nbsp;
                      <CurrentTime format="DD/MM/YYYY HH:mm:ss">{item.betPlacedDate}</CurrentTime>
                    </p>
                  </td>
                  <td className="text-align-left">{item.betPlacedIp}</td>
                  <td>
                    {item.marketBettingType === "ODDS" ? (item.side === 0 ? "Back" : "Lay") : (item.side === 0 ? "Yes" : "No")}
                  </td>
                  <td>
                    {item.averagePrice.toLocaleString(undefined, {
                      maximumFractionDigits: 3,
                      minimumFractionDigits: 3
                    })}
                  </td>
                  <td>{numberWithCommas(item.size)}</td>
                  <td>
                    <StringToRecolorNumber>{item.memberWin}</StringToRecolorNumber>
                  </td>
                  <td className={item.outcome === "WON" ? "-positive" : "-negative"}>{item.outcome}</td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  )
}
