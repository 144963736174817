import "./index.scss"
import moment from "moment"
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom"
import { useAuth } from "../../Providers/AuthProvider"
import { Navbar, Nav } from "react-bootstrap"
import DateTimeRangePicker from "../../components/DateTimeRangePicker"
import PnL from "./PnL"
import Credit from "./Credit"
import { useStorage } from "../../Providers/StorageProvider"
import MemberPnL from "./MemberPnL"
import MemberBreakdown from "./MemberBreakdown"
import StringToRecolorNumber from "../../components/StringToRecolorNumber"
import BetBreakdown from "../../components/BetBreakdown"
import ButtonCustom from "../../components/ButtonCustom"
import ChevronRightIcon from "mdi-react/ChevronRightIcon"
import { setCurrentPageNo, setDateTimeRangeRedux, setPnlData } from "../../redux/createSlice/pageNoForpnl";
import { useSelector, useDispatch } from 'react-redux';

export default ({ userId, userLevel }) => {
  const history = useHistory()
  const { requestsToApi } = useAuth()
  const [responseData, setResponseData] = useState()
  const [openedMarket, setOpenedMarket] = useState(null)
  const [downlineName, setDownlineName] = useState("market")
  const { userName } = useStorage();
  const [dataLoader, setDataLoader] = useState(false);

  const dispatch = useDispatch();
  const {
    location: { hash }
  } = history
  const now = new Date();
  const startOfToday = userId?.length == 11 ? moment(now.setHours(0, 0, 0, 0)).subtract(7, 'days') : moment(now.setHours(0, 0, 0, 0)).subtract(1, 'days');
  const endOfToday = moment(now).add(1, "days").subtract(1, "seconds")
  const [dateTimeRange, setDateTimeRange] = useState({
    start: startOfToday,
    end: endOfToday
  });

  const data = useSelector(state => state.pageNoForpnl.pnlData);
  const currentPageno = useSelector(state => state.pageNoForpnl.currentPage);
  const dateTimeRangeRedux = useSelector(state => state.pageNoForpnl.dateTimeRange);

  const [alert, setAlert] = useState({
    isDisplayed: false,
    message: "There is no data for this date range. Please select another date range."
  })
  // agency/HZ/report/statement?dl=HZ01&showDownlineView=true&from=1607374800702&targetUserCode=HZ01&to=1607461199702&type=pnl

  useEffect(() => {
    if (data != null && currentPageno > 1 && dateTimeRangeRedux == null) {
      setDataLoader(true);
      setResponseData(data);
      setDataLoader(false);
    } else if (dateTimeRangeRedux != null) {
      getDataStatement();
      dispatch(setCurrentPageNo(1));
    }else{
      getDataStatement();
    }
  }, [dateTimeRange, userId, hash, dateTimeRangeRedux]);


  const getDataStatement = async () => {
    try {
      setDataLoader(true);
      let cancelled = false
      setAlert({ ...alert, isDisplayed: false })
      const url =
        userLevel === "Member"
          ? `agency/${userName}/report/member-statement?memberCode=${userId}&from=${dateTimeRange.start.valueOf()}&to=${dateTimeRange.end.valueOf()}`
          : "agency/" +
          userName +
          "/report/statement?from=" +
          dateTimeRange.start.valueOf() +
          "&to=" +
          dateTimeRange.end.valueOf() +
          (userId ? `&dl=${userId}&targetUserCode=${userId}&showDownlineView=true` : "") +
          "&type=" +
          (hash === "#credit-statement" ? "credit" : "pnl")
      const response = await requestsToApi.get(url)
      setDataLoader(false);
      if (
        (response.data.result.statement && response.data.result.statement.length) ||
        (response.data.result.data && response.data.result.data.length)
      )
        setAlert({ ...alert, isDisplayed: false })
      else setAlert({ ...alert, isDisplayed: true })
      if (!cancelled) {
        setResponseData(response.data.result);
        dispatch(setPnlData(response.data.result));
        setDataLoader(false);
        dispatch(setDateTimeRangeRedux(null));
      };
      return () => (cancelled = true)

    } catch (e) {
      console.log("error", e);
      setDataLoader(false);
    }
    setDataLoader(false);
  }


  const goBack = () => {
    const splitedURL = history.location.pathname.split("/")
    history.push(splitedURL.slice(0, splitedURL.length - 2).join("/") + "#pnl-statement")
  }

  const followTheLink = href => () => {
    const path = history?.location?.pathname?.split("/").slice(0, 4).join("/");
    history.push(path + href);
    setResponseData([]);
  }

  return (
    <div className="account-statement">
      <div className="page-header">
        <div>
          <h1>Account Statement</h1>
          {hash !== "#market" ? (
            <DateTimeRangePicker dateTimeRange={dateTimeRange} onChangeFunc={setDateTimeRange} />
          ) : (
            <></>
          )}
        </div>
        {hash === "#market" ? (
          <ButtonCustom className="refresh__button" color="cyan">
            Download CSV
          </ButtonCustom>
        ) : (
          <></>
        )}
      </div>
      {alert.isDisplayed && hash !== "#market" ? (
        <div className="account-statement__alert">
          <span>{alert.message}</span>
        </div>
      ) : (
        <></>
      )}
      <div className="account-statement__main">
        {userLevel === "Member" ? (
          <>
            <p style={{ fontWeight: 700, marginBottom: 0 }} className="total-pnl">
              {"Total P&L: "}
              {responseData ? <StringToRecolorNumber>{responseData.totalPnl}</StringToRecolorNumber> : "-"}
            </p>
            {openedMarket ? (
              <MemberBreakdown
                userId={userId}
                dateTimeRange={dateTimeRange}
                goBack={() => setOpenedMarket(null)}
                openedMarket={openedMarket}
                prevPageData={
                  responseData?.data
                    ? responseData.data.map(item => ({ commission: item.commission, market: item.description?.market, action: item.action }))
                    : null
                }
              />
            ) : (
              <MemberPnL data={responseData} openMarket={market => setOpenedMarket(market)} />
            )}
          </>
        ) : (
          <>
            {hash !== "#market" ? (
              <>
                <Navbar className="report-tabs" variant="dark">
                  <Nav defaultActiveKey={hash && hash !== "#market" ? hash : "#pnl-statement"} className="mr-auto">
                    <Nav.Link href="#pnl-statement" onClick={followTheLink("#pnl-statement")}>
                      P&L Statement
                    </Nav.Link>
                    <Nav.Link href="#credit-statement" onClick={followTheLink("#credit-statement")}>
                      Credit Statement
                    </Nav.Link>
                  </Nav>
                </Navbar>
                <div className="main-page-content">
                  <div className="tab-content">
                    {hash === "#credit-statement" ? <Credit data={responseData} /> : <PnL data={responseData} dataLoader={dataLoader} />}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="downline-nav">
                  <a className="no-href" onClick={goBack}>
                    {history?.location.pathname.includes("/account/statement") ? "Account Statement" : "P&L Statement"}
                  </a>
                  <span>
                    <ChevronRightIcon size={17} />
                  </span>
                  <i>{downlineName}</i>
                </div>
                <BetBreakdown userLevel={userLevel} setDownlineName={setDownlineName} />
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}
