import React, { memo, useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import CurrentTime from '../../components/CurrentTime'
import StringToRecolorNumber from "../../components/StringToRecolorNumber"
import { Button } from 'antd'


export default memo(({ data }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);

  const startIndex = (currentPage - 1) * 500;
  const endIndex = startIndex + 500;

  const itemsToDisplay = data && data.statement ? data.statement.slice(startIndex, endIndex) : [];


  useEffect(() => {
    if (data && data.statement) {
      setLoader(false);
    }
  }, [data, itemsToDisplay]);


  const totalPages = Math.ceil((data && data.statement ? data.statement.length : 0) / 500);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {loader ? <div className='loader'></div> : (
        <>
          {itemsToDisplay.length === 0 ? <div className="pnl_size"><div>No Data Available</div></div> :
            <>
    <Table striped hover variant="dark">
      <thead>
        <tr>
          <th>Date</th>
          <th>Description</th>
          <th>To/From</th>
          <th>Amount</th>
          <th>Credit Balance</th>

        </tr>
      </thead>
      <tbody>
        {itemsToDisplay.map((item) => (
          <tr key={item.date}>
            <td>{<CurrentTime format='DD/MM/YYYY HH:mm:ss'>{item.date}</CurrentTime>}</td>
            <td>{item.actionDisplayName}</td>
            <td>{item.description}</td>
            <td><StringToRecolorNumber>{item.amount}</StringToRecolorNumber></td>
            <td><StringToRecolorNumber>{item.balance}</StringToRecolorNumber></td>
          </tr>
        ))}
      </tbody>
    </Table>
              {totalPages === 1 ? null : <div className="pagination">
      <Button type='primary'
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </Button>
      <span>Page {currentPage} of {totalPages}</span>
      <Button type='primary'
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </Button>
              </div>}
            </>}
        </>)}
  </>
  )
})
