import "./index.scss";
import { useEffect, useState, useMemo } from "react";
import ButtonCustom from "../../components/ButtonCustom";
import DateTimeRangePicker from "../../components/DateTimeRangePicker";
import { useHistory } from 'react-router-dom'
import { Spin } from 'antd';

import TextInputCustom from "../../components/TextInputCustom";
import moment from "moment";
import { useAuth } from "../../Providers/AuthProvider";

import DropdownCustom from "../../components/DropdownCustom"
import {
  dropdownUsersOptions,
} from './topWinnersData'
import SearchUserInput from "../../components/SearchUserInput"
import { Spinner } from "react-bootstrap";
const fmt = require('indian-number-format')

export default () => {
  const { requestsToApi } = useAuth();
  const [searchUserUrl, setSearchUserUrl] = useState([]);
  const [getReqTrigger, setGetReqTrigger] = useState(false);
  const history = useHistory();
  const myUserName = useMemo(() => sessionStorage.getItem("myUserName"), [])
  const myLoginName = useMemo(() => sessionStorage.getItem("myLoginName"), [])

  let now = new Date();
  const startOfToday = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  const endOfToday = moment(startOfToday).add(1, "days").subtract(1, "seconds");

  const [newOptions, setNewOptions] = useState({
  });

  const [sortByDesc, setSortByDesc] = useState({ turnover: true, percentage: false });
  const [sortedReports, setSortedReports] = useState([]);
  const [isAutoRefresh, setIsAutoRefresh] = useState(false);

  const [loader, setLoader] = useState(false);

  const dropdownUsersIdOptions = {
    "User": 55,
    "Master": 54,
    "Super Master": 53,
    "Admin": 52

  }

  const urlParams =
    "?" +
    "loginName=" + myLoginName +
    "&fromDate=" + (!newOptions?.date?.start ? startOfToday : newOptions?.date?.start) +
    "&toDate=" + (!newOptions?.date?.end ? endOfToday : newOptions?.date?.end) +
    "&hierarchy=" + dropdownUsersIdOptions[newOptions.userDropdown]


  const changeValue = (value, filterTitle) => {
    if (filterTitle === "userDropdown") {
      setNewOptions({ ...newOptions, userDropdown: [value], dropdownUsersOptions: 'User' })
    } else {
      setNewOptions({ ...newOptions, [filterTitle]: value })
    }
  };


  const handleClickClear = () => {
    setNewOptions({ userCode: "", });
  };

  const handleClickApply = async () => {
    if (!dropdownUsersIdOptions[newOptions.userDropdown]) {
      return alert("Please select hierarchy");
    }
    setLoader(true);
    try {
      const response = await requestsToApi.get("/agency/" + myUserName + "/report/getTopWinner" + urlParams);
      if (response.data.result != null) {
        setSortedReports(response.data.result.sort((a, b) => { if (a.PL > b.PL) return -1; }));
        setLoader(false);
      } else {
        setSortedReports([]);
        setLoader(false);
      }

    } catch (error) {
      console.log(error?.message);
      setLoader(true);
    }
  };

  const changeSort = (key) => {
    setSortByDesc({ turnover: false, percentage: false, [key]: !sortByDesc[key] });
    if (sortedReports.length >= 2) {
      if (key === "percentage") {
        setSortedReports(sortByDesc[key] ? (sortedReports?.sort((a, b) => { if ((100 * a.PL / a.TURNOVER) > (100 * b.PL / b.TURNOVER)) return -1; })) : (sortedReports?.sort((a, b) => { if ((100 * a.PL / a.TURNOVER) < (100 * b.PL / b.TURNOVER)) return -1; })));
      }

      if (key === "turnover") {
        setSortedReports(sortByDesc[key] ? (sortedReports?.sort((a, b) => { if (a.TURNOVER > b.TURNOVER) return -1; })) : (sortedReports?.sort((a, b) => { if (a.TURNOVER < b.TURNOVER) return -1; })));
      }
    }
  };

  const gotoSecretRoom=(r)=>{
    window.open("/secret-room/" + (r.USR_MEMBERCODE).substring(0, 8) + "/" + (r.USR_MEMBERCODE));
  }

  return (
    <div className="top-winner">
      <div className="top-winner__header">
        <div className="top-winner__header_date-rows">
          <div className="top-winner__header_date-row">
            <h1>Top Winners</h1>
          </div>
          <div className="top-winner__header_date-unput-row">
            {/*<SearchUserInput*/}
            {/*  placeholder="Agency/Member"*/}
            {/*  dropdownClickFunc={(user) => {*/}
            {/*    console.log(user,"user");*/}
            {/*    changeValue(user.userCode, "memberCode")*/}
            {/*    newOptions.memberCode = user.userCode*/}
            {/*  }}*/}
            {/*  defaultInputValue=""*/}
            {/*  type="none"*/}
            {/*/>*/}
            {/*<TextInputCustom className="top-winner__textInputCustom" onChange={(value) => changeValue(value, "userCode")} placeholder="Login Name">*/}
            {/*  {newOptions.userCode}*/}
            {/*</TextInputCustom>*/}
            <DropdownCustom
              title="Hierarchy :"
              options={dropdownUsersOptions}
              activeOption={newOptions.userDropdown}
              onChange={(value) => changeValue(value, "userDropdown")}
            />
            <DateTimeRangePicker dateTimeRange={newOptions.date} onChangeFunc={(value) => changeValue(value, "date")} />
          </div>
        </div>
        <div className="top-winner__header-search-column">
          <div className="top-winner__header-search-column-buttons">
            {/*<span className="top-winner__header-search-column-buttons-autoRefreshTitle">Auto Refresh</span>*/}
            {/*<input type="checkbox" className="top-winner__header-search-column-buttons-autoRefresh" onChange={() => setIsAutoRefresh(!isAutoRefresh)}/>*/}
            <span style={{ marginRight: "10px" }}> <ButtonCustom onClick={handleClickApply} color="cyan">Apply</ButtonCustom></span>
            <span style={{ color: 'white' }}> <ButtonCustom onClick={handleClickClear} color="red">Clear</ButtonCustom>
            </span>
          </div>
        </div>
      </div>
      <div className="top-winner__table">
        <ul className="top-winner__table-header">
          <li>User</li>
          <li>Login Name</li>
          <li>Member Code</li>
          <li>PL</li>
          <li>Commission</li>
          <li onClick={() => changeSort("turnover")}>
            <span className={`top-winner__table-header-sort ${sortByDesc.turnover ? "" : "up"}`}>Turnover</span>
          </li>
          <li onClick={() => changeSort("percentage")}>
            <span className={`top-winner__table-header-sort ${sortByDesc.percentage ? "" : "up"}`}>Percentage(%)</span>
          </li>

        </ul>
        {
          loader &&
          <div className=" flex align-items-center justify-content-center" style={{ width: "100%", height: "60vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Spinner animation="border" size="sm" style={{height:'4rem', width:'4rem'}} />
          </div>
        }
        {!loader &&
          <div className="top-winner__table-body">
            {sortedReports?.map((r, index) =>
              <ul key={r.requestId + index}
                className="top-winner__table-body-row">
                <li>{r.USR_ID}</li>
                <li style={{color:'#10827d', cursor:'pointer'}} onClick={()=>gotoSecretRoom(r)}>{(r.USR_LOGINNAME).split('.')[1]}</li>
                <li>{r?.USR_MEMBERCODE}</li>
                <li>{fmt.formatFixed(parseFloat(r.PL), 2)}</li>
                <li>{fmt.formatFixed(parseInt(r.COMM), 2)}</li>
                <li>{fmt.formatFixed(parseInt(r.TURNOVER), 2)}</li>
                <li>{parseFloat(100 * r.PL / r.TURNOVER).toFixed(2)}</li>
              </ul>,
            )}
          </div>}
      </div>
    </div>
  );
};
