import { Button, Table } from "react-bootstrap"
import StringToRecolorNumber from "../../components/StringToRecolorNumber"
import { useHistory } from "react-router-dom"
import { useStorage } from "../../Providers/StorageProvider"
import CurrentTime from "../../components/CurrentTime"
import moment from "moment"
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPageNo } from "../../redux/createSlice/pageNoForpnl"

export default ({ data, dataLoader }) => {
  const history = useHistory()
  const { userLevel } = useStorage();
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const currentPageno = useSelector(state => state.pageNoForpnl.currentPage);



  const startIndex = (currentPage - 1) * 500;
  const endIndex = startIndex + 500;

  const itemsToDisplay = data && data.statement ? data.statement.slice(startIndex, endIndex) : [];



  useEffect(() => {
    if (currentPageno > 1) {
      setCurrentPage(currentPageno);
    } else {
      setCurrentPage(1);
    }
  }, [currentPageno]);

  const totalPages = Math.ceil((data && data.statement ? data.statement.length : 0) / 500);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const tableElement = document.querySelector(".pnl_size");
    tableElement.scrollIntoView({ behavior: "smooth" });
    tableElement.scrollTop = 0;
    dispatch(setCurrentPageNo(page));
  };

  return (
    <>
      {
        dataLoader ? <div className="loader"></div> : (
          <>
            <p className="total-pnl">
              {"Total P&L: "}
              {data && data.totalPnl ? <StringToRecolorNumber>{data.totalPnl}</StringToRecolorNumber> : "-"}
            </p>
            {itemsToDisplay.length == 0 && data && !dataLoader ? <div className="pnl_size"><div>No Data Available</div></div> :
              <>
                <div className="pnl_size">
                  <Table striped hover variant="dark">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Description</th>
                        <th />
                        {/* <th style={{ textAlign: "right" }}>P&L</th> */}
                        <th>P&L</th>
                        {/* {userLevel === "Agent" && (
                <>
                  <th style={{ textAlign: "right" }}>Credit Limit</th>
                  <th style={{ textAlign: "right" }}>Balance</th>
                </>
              )} */}
                      </tr>
                    </thead>
                    <tbody>
                      {data && data?.statement && data?.statement?.length ? (
                        itemsToDisplay.map(item => (
                          <tr key={item.date + Math.random()}>
                            <td>{<CurrentTime format="DD/MM/YYYY HH:mm:ss">{item.date}</CurrentTime>}</td>
                            <td>{!!item?.actionDisplayName ? item?.actionDisplayName : ''}</td>
                            <td>
                              {!!item?.description ?
                                <a
                                  className="no-href"
                                  onClick={() =>
                                    history.push(
                                      history?.location.pathname + "/bet-breakdown/" + item?.description?.market + "#market"
                                    )
                                  }
                                >
                                  {item.description.eventName.replace(
                                    "${HH:mm}",
                                    moment.unix(item?.description?.marketTime / 5000).utcOffset(330).format("HH:mm")) +
                                    " - " +
                                    item?.description?.marketName +
                                    ` ${!!item?.description?.selectionName ? item?.description?.selectionName : ''}`}
                                </a> : <></>}
                            </td>
                            {/* <td style={{ textAlign: "right" }}> */}
                            <td>
                              <StringToRecolorNumber>{item?.amount}</StringToRecolorNumber>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </Table>
                  {totalPages === 1 ? null : <div className="pagination">
                    <Button type='primary'
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <Button type='primary'
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </Button>
                  </div>}
                </div>
              </>}

          </>
        )
      }

    </>
  )
}
