import { useEffect, useState, Fragment } from "react"
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap"
import ButtonCustom from "../../components/ButtonCustom"
import { useAuth } from "../../Providers/AuthProvider"
import "./index.scss"
import { useStorage } from "../../Providers/StorageProvider"
import RadioCustom from "../../components/RadioCustom"
import moment from "moment/moment"
import TextInputCustom from "../../components/TextInputCustom"

export default ({ userData }) => {
  const [reportsData, setReportsData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [activeToggle, setActiveToggle] = useState("Deposit");
  const [requestIdInput, setRequestIdInput] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const { requestsToApi } = useAuth()
  const { setIsLoading } = useStorage();
  const { userName } = useStorage();

  const getRequest = async () => {
    try {
      setIsLoading(true)
      const response = await requestsToApi.get(`/agency/${userName}/report/dw?memberCode=${userData.name}&requestId=${requestIdInput.toString()}`)
      setReportsData(response.data.result.sort((a, b) => { if (a.createdAt > b.createdAt) return -1; }));
      // setReportsData(response.data.result);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  };

  useEffect(async () => {
    getRequest()
  }, [userData]);

  useEffect(async () => {
    if (reportsData) {
      if (requestIdInput) {
        setFilteredData(reportsData);
        setActiveToggle("")
      } else {
        setFilteredData(reportsData.filter(item => item.type === activeToggle))
      }
    }
  }, [reportsData, activeToggle]);

  const radioToggle = e => {
    setActiveToggle(e.target.attributes["status"].value)
    setRequestIdInput("")
  }

  const handleOpenAttachment = (url) => {
    const link = "https://paymentslips.s3.ap-south-1.amazonaws.com/" + url;
    setImageUrl(link);
  }

  const handleCloseAttchement = () => {
    setImageUrl(null);
  }

  const gateWayData = (data) => {
    if (data==='' || data===null || data===undefined) {
      return <div>N/A</div>
    }else if (typeof data === "object") {
      return (
        <div>
          <div>{data?.name}</div>
          <div>{data?.acNo}</div>
          <div>{data?.IFSC}</div>
          <div>{data?.bankName}</div>
        </div>
      )
    }else{
      return <div>{data}</div>
    }
  }


  return (
    <>
      <div className="dw-support-reports-page">
        <div className="dw-support-reports-page__header">
          <div className="dw-support-reports-page__header-input-wrapper">
            <TextInputCustom
              className="customer-reports__textInputCustom"
              onChange={(value) => setRequestIdInput(value)}
              placeholder="Request Id"
              color="white"
            >
              {requestIdInput}
            </TextInputCustom>
            <ButtonCustom onClick={() => {
              getRequest()
            }} color="cyan">Apply</ButtonCustom>
          </div>
          <div className="bet-list-report__radios">
            <RadioCustom name="category" status="Deposit" type="green" onChange={radioToggle} checked>
              Deposit
            </RadioCustom>
            <RadioCustom name="category" status="Withdrawal" type="green" onChange={radioToggle}>
              Withdrawal
            </RadioCustom>
          </div>
        </div>
        <div className="dw-support-reports-page__table">
          <ul className="dw-support-reports-page__table-header">
            <li>Request Id</li>
            <li>Status</li>
            <li>Remark</li>
            <li>Type</li>
            <li>UTR no</li>
            <li>Amount</li>
            <li>Create Date</li>
            <li>Update Date</li>
            <li>IP</li>
            {/*<li>REMARK</li>*/}
            <li>{activeToggle === "Deposit" ? "Gateway" : "Account"}</li>
          </ul>
          <div className="dw-support-reports-page__table-body">
            {filteredData?.map((report, index) =>
              <ul key={report.requestId + index} className="dw-support-reports-page__table-body-row">
                <li>{report?.requestId || "N/A"}</li>
                <li>{report?.status || "N/A"}</li>
                <li>{report?.remarks || "N/A"}</li>
                <li>{report?.type}</li>
                <li>{report?.utr}
                  {activeToggle === "Deposit" && report?.fileName &&
                    (
                      <span style={{ cursor: "pointer" }} onClick={() => handleOpenAttachment(report?.fileName)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill='white'><path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" /></svg>
                      </span>
                    )}
                </li>
                <li>{report?.amount}</li>
                <li className="dw-support-reports-page__table-body-row-date">
                  <div>{moment(report?.createdAt)?.format("DD.MM.YY")}</div>
                  <div className="time">{moment(report?.createdAt)?.format("LTS")}</div>
                </li>
                <li className="cdw-support-reports-page__table-body-row-date">
                  {report?.lastUpdateTime &&
                    <div>
                      <div>{moment(report?.lastUpdateTime)?.format("DD.MM.YY")}</div>
                      <div className="time">{moment(report?.lastUpdateTime)?.format("LTS")}</div>
                    </div>}
                </li>
                <li>{report?.IP}</li>
                {/*<li>{report?.remarks}</li>*/}

                {activeToggle === "Deposit" ?
                  <li className="customer-reports__table-body-row-date">
                    {gateWayData(report?.gateway)}
                  </li>
                  :
                  <li className="customer-reports__table-body-row-date">
                    {report?.account &&
                      <div>
                        <div>{report.account?.accountNo}</div>
                        <div>{report.account?.accountHolderName}</div>
                        <div>{report.account?.branchName}</div>
                        <div>{report.account?.IFSC}</div>
                        <div>{report.account?.bankName}</div>
                      </div>}
                  </li>
                }
              </ul>
            )}
          </div>
        </div>
      </div>
      {imageUrl &&
        (
          <div className="attachment_popup">
            <div className="popup_body">
              <div className="close" onClick={handleCloseAttchement}>
                <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" fill="red" height="24" width='24' strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" fillRule="nonzero" /></svg>
              </div>
              <img src={imageUrl} className="img-fluid attached-img" alt="Attachment" />
              <div className="btn_close">
                <ButtonCustom onClick={handleCloseAttchement}>Close</ButtonCustom>
              </div>
            </div>
          </div>
        )}
    </>
  )
}
