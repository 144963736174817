import { createSlice } from "@reduxjs/toolkit";


const pageSlice = createSlice({
    name: "pageNoForpnl",
    initialState: {
        pageNoForpnl: 1,
        currentPage: 1,
        savedScrollPosition: 0,
        pnlData: null,
        dateTimeRange: null,
    },
    reducers: {

        setCurrentPageNo: (state, action) => {
            state.currentPage = action.payload;
        },
        setPnlData: (state, action) => {
            state.pnlData = action.payload;
        },

        setDateTimeRangeRedux: (state, action) => {
            state.dateTimeRange = action.payload;
        }
    },
});

export const { setCurrentPageNo, setPnlData, setDateTimeRangeRedux } = pageSlice.actions;
export default pageSlice.reducer;